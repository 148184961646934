
import {
  defineComponent, onBeforeMount, onMounted, reactive, ref, watch,
} from 'vue';
import { useElementSize } from '@vueuse/core';
import { useRoute } from 'vue-router';
import CampoEmail from '@/core/components/UI/CampoEmail.vue';
import storeApp from '@/store/storeApp';
import { useAppBase } from '@/core/composables/AppBase';
import {
  IArea, IAreaBox, IAreaBoxStatus,
} from '@/models/Entidades/IArea';
import SelecionarDataInicialFinal from '@/core/components/UI/SelecionarDataInicialFinal.vue';
import SelecionarData from '@/core/components/UI/SelecionarData.vue';
import RadioFormaPagamento from '@/components/FormasPagamentos/RadioFormaPagamento.vue';
import ComboTipoPessoa from '@/components/ComboTipoPessoa.vue';
import CampoCep from '@/core/components/UI/CampoCep.vue';
import CampoCpf from '@/core/components/UI/CampoCpf.vue';
import CampoCnpj from '@/core/components/UI/CampoCnpj.vue';
import CampoTelefone from '@/core/components/UI/CampoTelefone.vue';
import ComboCidade from '@/components/ComboCidade.vue';
import ServicoReserva from '@/servicos/ServicoReserva';
import { EBoxStatus } from '@/models/Enumeradores/EBoxStatus';
import GerenciadorAutenticacao from '@/core/gerenciadores/GerenciadorAutenticacao';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import UtilitarioMascara from '@/core/utilitarios/UtilitarioMascara';
import { IDTOAreaDisponibilidade } from '@/models/DTO/IDTOAreaDisponibilidade';
import { IReserva, IReservaEquipamento } from '@/models/Entidades/IReserva';
import UtilitarioData from '@/core/utilitarios/UtilitarioData';
import { ICliente, IClienteEndereco } from '@/models/Entidades/ICliente';
import ServicoUtilitario from '@/servicos/ServicoUtilitario';
import { IFormaPagamento } from '@/models/Entidades/IFormaPagamento';
import { EAplicacaoAcrescimoDesconto } from '@/models/Enumeradores/EAplicacaoAcrescimoDesconto';
import CampoAdicionarDiminuirNumero from '@/core/components/UI/CampoAdicionarDiminuirNumero.vue';
import { ETipoControleArea } from '@/models/Enumeradores/ETipoControle';
import { IEquipamento } from '@/models/Entidades/IEquipamento';
import { IDTOReservaCartaoDadosCartao, IDTOReservaCartaoDadosTitularCartao, IDTOReservaCriacao } from '@/models/DTO/IDTOReservaCriacao';
import { ETipoIntegracaoPagamento } from '@/models/Enumeradores/ETipoIntegracaoPagamento';
import { ETipoFormaPagamento } from '@/models/Enumeradores/ETipoFormaPagamento';
import { EStatusResposta } from '@/core/models/IResposta';
import { EStatusReserva } from '@/models/Enumeradores/EStatusReserva';
import { IDTOReservaBox } from '@/models/DTO/IDTOAreaReserva';

export default defineComponent({
  name: 'ReservaPublica',
  components: {
    SelecionarDataInicialFinal,
    SelecionarData,
    CampoEmail,
    ComboTipoPessoa,
    CampoCpf,
    CampoCnpj,
    CampoCep,
    CampoTelefone,
    ComboCidade,
    CampoAdicionarDiminuirNumero,
    RadioFormaPagamento,
  },
  setup() {
    const {
      appBase, apresentarComunicacaoApi, apresentarResposta, obterCodigoRota,
    } = useAppBase();
    const el = ref(null);
    const { width, height } = useElementSize(el);
    const route = useRoute();
    const servicoReserva = new ServicoReserva();
    const servicoUtilitario = new ServicoUtilitario();
    const gerenciadorAutenticacao = new GerenciadorAutenticacao();
    const state = reactive({
      passo: 1,
      mensagemAlerta: '',
      reservaCriacao: {} as IDTOReservaCriacao,
      areasDisponiveis: [] as IDTOAreaDisponibilidade[],
      periodoDisponivel: false,
      formaPagamento: {} as IFormaPagamento,
      area: {} as IArea,
      equipamentos: [] as IEquipamento[],
      boxSelecionado: {} as IAreaBox,
      statusBoxDisponivel: {} as IAreaBoxStatus,
      boxesReservados: [] as IDTOReservaBox[],
      coordenadasOriginaisBoxes: [] as IAreaBox[],
      mapaLarguraOriginal: 0,
      mapaAlturaOriginal: 0,
      chaveImg: 1,
      carregaPreviaMapa: false,
      quantidadeDias: 0,
      valorDiaria: 0,
      apresentarDadosCliente: false,
      indexPreview: -1,
      passoIndentificacao: 1,
      cpfCnpjIdentificacao: '',
      incluirEquipamentos: false,
    });

    state.reservaCriacao.quantidadeParcelas = 1;
    state.reservaCriacao.reserva = {} as IReserva;
    state.reservaCriacao.reserva.status = EStatusReserva.Pendente;
    state.reservaCriacao.reserva.codigo = 0;
    state.reservaCriacao.reserva.codigoArea = 0;
    state.reservaCriacao.reserva.codigoBox = 0;
    state.reservaCriacao.reserva.codigoCliente = 0;
    state.reservaCriacao.reserva.codigoFormaPagamento = 4;
    state.reservaCriacao.reserva.qtdePessoasInteira = 1;
    state.reservaCriacao.reserva.qtdePessoasMeia = 0;
    state.reservaCriacao.reserva.qtdePessoasIsenta = 0;
    state.reservaCriacao.reserva.equipamentos = [];
    state.reservaCriacao.cliente = {} as ICliente;
    state.reservaCriacao.cliente.codigo = 0;
    state.boxSelecionado = {} as IAreaBox;
    state.equipamentos = [];

    state.reservaCriacao.dadosCartao = {} as IDTOReservaCartaoDadosCartao;
    state.reservaCriacao.dadosCartao.mesExpiracao = '01';
    state.reservaCriacao.dadosCartao.anoExpiracao = '2023';
    state.reservaCriacao.dadosTitularCartao = {} as IDTOReservaCartaoDadosTitularCartao;

    let corDisponivel = ref(state.statusBoxDisponivel.cor);

    const visible = ref<boolean>(false);

    const setVisible = (value:boolean): void => {
      visible.value = value;
    };

    function enviarAtualizacaoAltura(height:number) {
      window.parent.postMessage({ height }, '*');
    }

    function updateParkingSpacePositions() {
      state.chaveImg += 1;
      state.carregaPreviaMapa = true;

      setTimeout(() => {
        const parkingImage = document.querySelector('#AreaImgMapa') as any;
        if (parkingImage !== null) {
          const widthRatio = parkingImage.offsetWidth / state.mapaLarguraOriginal;
          const heightRatio = parkingImage.offsetHeight / state.mapaAlturaOriginal;

          state.area.mapaLargura = parkingImage.offsetWidth;
          state.area.mapaAltura = parkingImage.offsetHeight;
          state.area.boxes = UtilitarioGeral.instanciaObjetoLocal(state.coordenadasOriginaisBoxes);

          for (let index = 0; index < state.area.boxes.length; index += 1) {
            state.area.boxes[index].largura = (state.coordenadasOriginaisBoxes[index].largura * widthRatio);
            state.area.boxes[index].altura = (state.coordenadasOriginaisBoxes[index].altura * heightRatio);
            state.area.boxes[index].posicaoX = (state.coordenadasOriginaisBoxes[index].posicaoX * widthRatio);
            state.area.boxes[index].posicaoY = (state.coordenadasOriginaisBoxes[index].posicaoY * heightRatio);
          }

          state.carregaPreviaMapa = false;
        }
      }, 1000);
    }

    async function obterClienteCpfCnpj() {
      const cliente = await servicoReserva.obterClientePorCpfCnpj(state.reservaCriacao.cliente.cpfCnpj);
      if (cliente !== undefined && cliente.codigo > 0) {
        state.reservaCriacao.cliente = cliente;
        state.reservaCriacao.reserva.codigoCliente = cliente.codigo;
      }
    }

    async function consultaCep() {
      if (UtilitarioGeral.valorValido(state.reservaCriacao.cliente.enderecos[0].cep) && UtilitarioGeral.validaValorComLimiteCaracteres(state.reservaCriacao.cliente.enderecos[0].cep, 8)) {
        const retornoConsultaCep = await servicoUtilitario.consultaCep(state.reservaCriacao.cliente.enderecos[0].cep);
        if (retornoConsultaCep !== undefined) {
          if (!UtilitarioGeral.valorValido(state.reservaCriacao.cliente.enderecos[0].endereco)) {
            state.reservaCriacao.cliente.enderecos[0].endereco = retornoConsultaCep.logradouro;
          }

          if (!UtilitarioGeral.valorValido(state.reservaCriacao.cliente.enderecos[0].complemento)) {
            state.reservaCriacao.cliente.enderecos[0].complemento = retornoConsultaCep.complemento;
          }

          state.reservaCriacao.cliente.enderecos[0].bairro = retornoConsultaCep.bairro;
          state.reservaCriacao.cliente.enderecos[0].codigoCidade = retornoConsultaCep.codigoCidade;
        }
      }
    }

    async function obterClienteEmail() {
      if (UtilitarioGeral.valorValido(state.reservaCriacao.cliente.email)) {
        const cliente = await servicoReserva.obterClientePorEmail(state.reservaCriacao.cliente.email);
        if (cliente !== undefined && cliente.codigo > 0) {
          state.reservaCriacao.cliente = cliente;
          state.reservaCriacao.reserva.codigoCliente = cliente.codigo;
          consultaCep();
          state.passoIndentificacao = 2;
          return;
        }
        state.apresentarDadosCliente = true;
      } else {
        state.mensagemAlerta = 'Por favor, digite o seu e-mail!';
      }
    }

    function calcularValoresReserva() {
      state.incluirEquipamentos = false;
      state.valorDiaria = 0;
      state.reservaCriacao.reserva.valorTotal = 0;

      let valorBox = state.area.valorBox;
      if (UtilitarioGeral.validaLista(state.area.valoresPorPeriodo)) {
        const dataCheckIn = UtilitarioData.converterDataParaDayJs(state.reservaCriacao.reserva.dataCheckIn);
        for (let index = 0; index < state.area.valoresPorPeriodo.length; index += 1) {
          const dataInicioPeriodo = UtilitarioData.converterDataParaDayJs(`${state.reservaCriacao.reserva.dataCheckIn.substring(0, 4)}-${state.area.valoresPorPeriodo[index].periodoInicial.substring(3, 5)}-${state.area.valoresPorPeriodo[index].periodoInicial.substring(0, 2)}`);
          const dataFimPeriodo = UtilitarioData.converterDataParaDayJs(`${state.reservaCriacao.reserva.dataCheckOut.substring(0, 4)}-${state.area.valoresPorPeriodo[index].periodoFinal.substring(3, 5)}-${state.area.valoresPorPeriodo[index].periodoFinal.substring(0, 2)}`);
          if (dataCheckIn >= dataInicioPeriodo && dataCheckIn <= dataFimPeriodo) {
            valorBox = state.area.valoresPorPeriodo[index].valorBox;
            break;
          }
        }
      }

      state.reservaCriacao.reserva.valorTotal += (state.reservaCriacao.reserva.qtdePessoasInteira * valorBox) * state.quantidadeDias;
      state.reservaCriacao.reserva.valorTotal += (state.reservaCriacao.reserva.qtdePessoasMeia * (valorBox / 2)) * state.quantidadeDias;
      if (state.area.valorMinimoReserva > 0 && state.reservaCriacao.reserva.valorTotal < state.area.valorMinimoReserva) {
        state.reservaCriacao.reserva.valorTotal = state.area.valorMinimoReserva;
      }

      if (state.reservaCriacao.reserva.valorTotal > 0) {
        if (state.area.tipoControle === ETipoControleArea.ControleEquipamento) {
          state.reservaCriacao.reserva.equipamentos.forEach((equipamento) => {
            if (equipamento.quantidade > 0 && equipamento.valor > 0) {
              state.reservaCriacao.reserva.valorTotal += (equipamento.quantidade * equipamento.valor) * state.quantidadeDias;
              state.incluirEquipamentos = true;
            }
          });
        } else {
          state.reservaCriacao.reserva.equipamentos = [];
        }

        if (state.formaPagamento.aplicacaoAcrescimo === EAplicacaoAcrescimoDesconto.Valor && state.formaPagamento.valorAcrescimo > 0) {
          state.reservaCriacao.reserva.valorTotal += state.formaPagamento.valorAcrescimo;
        } else if (state.formaPagamento.aplicacaoAcrescimo === EAplicacaoAcrescimoDesconto.Percentual && state.formaPagamento.valorAcrescimo > 0) {
          const valorAcrescimo = ((state.reservaCriacao.reserva.valorTotal * state.formaPagamento.valorAcrescimo) / 100);
          state.reservaCriacao.reserva.valorTotal += valorAcrescimo;
        }
      }

      state.valorDiaria = valorBox;
      if (state.formaPagamento.aplicacaoAcrescimo === EAplicacaoAcrescimoDesconto.Valor && state.formaPagamento.valorAcrescimo > 0) {
        state.valorDiaria += state.formaPagamento.valorAcrescimo;
      } else if (state.formaPagamento.aplicacaoAcrescimo === EAplicacaoAcrescimoDesconto.Percentual && state.formaPagamento.valorAcrescimo > 0) {
        const valorAcrescimo = ((state.valorDiaria * state.formaPagamento.valorAcrescimo) / 100);
        state.valorDiaria += valorAcrescimo;
      }
    }

    async function obterAreaComBoxReservados() {
      appBase.carregando = true;
      const areaReserva = await servicoReserva.obterAreaComBoxReservados(state.reservaCriacao.reserva.codigoArea, state.reservaCriacao.reserva.dataCheckIn, state.reservaCriacao.reserva.dataCheckOut);
      state.area = areaReserva.area;
      if (state.area.tipoControle === ETipoControleArea.ControleEquipamento) {
        state.equipamentos = await servicoReserva.obterEquipamentos();
        if (UtilitarioGeral.validaLista(state.equipamentos)) {
          state.reservaCriacao.reserva.equipamentos = [];
          state.equipamentos.forEach((equipamento) => {
            const equipamentoReserva:IReservaEquipamento = {} as IReservaEquipamento;
            equipamentoReserva.codigo = 0;
            equipamentoReserva.codigoReserva = 0;
            equipamentoReserva.codigoEquipamento = equipamento.codigo;
            equipamentoReserva.nome = equipamento.nome;
            equipamentoReserva.quantidade = 0;
            equipamentoReserva.valor = equipamento.valor;
            state.reservaCriacao.reserva.equipamentos.push(equipamentoReserva);
          });
        }
      }
      state.statusBoxDisponivel = state.area.boxesStatus.find((c) => c.status === EBoxStatus.Disponivel) || {} as IAreaBoxStatus;
      corDisponivel = ref(state.statusBoxDisponivel.cor);
      state.boxesReservados = areaReserva.boxesReservados;
      state.mapaLarguraOriginal = state.area.mapaLargura;
      state.mapaAlturaOriginal = state.area.mapaAltura;
      state.coordenadasOriginaisBoxes = state.area.boxes;
      updateParkingSpacePositions();
      appBase.carregando = false;
    }

    async function definirAreaSelecionada(codigoArea:number) {
      state.reservaCriacao.reserva.codigoArea = codigoArea;
      await obterAreaComBoxReservados();
      calcularValoresReserva();
    }

    async function definePrimeiroPasso() {
      await obterAreaComBoxReservados();
      state.reservaCriacao.reserva.codigoBox = 0;
      state.mensagemAlerta = '';
      state.passo = 1;
    }
    async function consultarDisponibilidade() {
      state.periodoDisponivel = false;
      if (!UtilitarioGeral.valorValido(state.reservaCriacao.reserva.dataCheckIn)) {
        state.mensagemAlerta = 'Por favor, prencha a sua data de Check-in.';
        return;
      }

      if (!UtilitarioGeral.valorValido(state.reservaCriacao.reserva.dataCheckOut)) {
        state.mensagemAlerta = 'Por favor, prencha a sua data de Check-Out.';
        return;
      }

      state.areasDisponiveis = await servicoReserva.consultarDisponibilidade(state.reservaCriacao.reserva.dataCheckIn, state.reservaCriacao.reserva.dataCheckOut);
      if (!UtilitarioGeral.validaLista(state.areasDisponiveis)) {
        state.mensagemAlerta = 'Desculpe-nos não temos disponibilidade no período informado.';
        return;
      }

      state.periodoDisponivel = true;
      state.quantidadeDias = UtilitarioData.verificaDiferencaDiasEntreDatas(state.reservaCriacao.reserva.dataCheckIn, state.reservaCriacao.reserva.dataCheckOut);
      if (state.quantidadeDias === 0) {
        state.quantidadeDias = 1;
      }
      state.formaPagamento = await servicoReserva.obterFormaPagamento(4);

      await definirAreaSelecionada(state.areasDisponiveis[0].codigo);
    }

    async function consultarDisponibilidadeBoxSelecionado() {
      state.periodoDisponivel = false;
      state.mensagemAlerta = '';
      if (!UtilitarioGeral.valorValido(state.reservaCriacao.reserva.dataCheckIn)) {
        state.mensagemAlerta = 'Por favor, prencha a sua data de Check-in.';
        return;
      }

      if (!UtilitarioGeral.valorValido(state.reservaCriacao.reserva.dataCheckOut)) {
        state.mensagemAlerta = 'Por favor, prencha a sua data de Check-Out.';
        return;
      }

      const areaReserva = await servicoReserva.obterAreaComBoxReservados(state.reservaCriacao.reserva.codigoArea, state.reservaCriacao.reserva.dataCheckIn, state.reservaCriacao.reserva.dataCheckOut);
      const boxReservado = areaReserva.boxesReservados.some((c) => c.codigoBox === state.reservaCriacao.reserva.codigoBox);
      if (boxReservado) {
        state.mensagemAlerta = 'O Box selecionado já possui reserva neste período.';
        return;
      }

      state.periodoDisponivel = true;
      state.quantidadeDias = UtilitarioData.verificaDiferencaDiasEntreDatas(state.reservaCriacao.reserva.dataCheckIn, state.reservaCriacao.reserva.dataCheckOut);
      if (state.quantidadeDias === 0) {
        state.quantidadeDias = 1;
      }

      calcularValoresReserva();
    }

    async function consultaCnpj() {
      const cliente = await servicoReserva.obterClientePorCpfCnpj(state.reservaCriacao.cliente.cpfCnpj);
      if (cliente !== undefined && cliente.codigo > 0) {
        state.reservaCriacao.cliente = cliente;
        state.reservaCriacao.reserva.codigoCliente = cliente.codigo;
        consultaCep();
        return;
      }

      if (UtilitarioGeral.valorValido(state.reservaCriacao.cliente.cpfCnpj) && UtilitarioGeral.validaValorComLimiteCaracteres(state.reservaCriacao.cliente.cpfCnpj, 14)) {
        const retornoConsultaCnpj = await servicoUtilitario.consultaCnpj(state.reservaCriacao.cliente.cpfCnpj);
        if (retornoConsultaCnpj !== undefined && UtilitarioGeral.valorValido(retornoConsultaCnpj.razaoSocial)) {
          state.reservaCriacao.cliente.nomeRazaoSocial = retornoConsultaCnpj.razaoSocial;
          state.reservaCriacao.cliente.email = retornoConsultaCnpj.contato.email;
          state.reservaCriacao.cliente.telefone = retornoConsultaCnpj.contato.telefonePrincipal;

          if (UtilitarioGeral.validaLista(state.reservaCriacao.cliente.enderecos)) {
            if (!UtilitarioGeral.valorValido(state.reservaCriacao.cliente.enderecos[0].endereco)) {
              state.reservaCriacao.cliente.enderecos[0].cep = retornoConsultaCnpj.endereco.cep;
              state.reservaCriacao.cliente.enderecos[0].numero = retornoConsultaCnpj.endereco.numero;
              state.reservaCriacao.cliente.enderecos[0].endereco = retornoConsultaCnpj.endereco.logadouro;
              state.reservaCriacao.cliente.enderecos[0].complemento = retornoConsultaCnpj.endereco.complemento;
              state.reservaCriacao.cliente.enderecos[0].bairro = retornoConsultaCnpj.endereco.bairro;
              state.reservaCriacao.cliente.enderecos[0].codigoCidade = retornoConsultaCnpj.endereco.codigoCidade;
            }
          } else {
            const endereco:IClienteEndereco = {} as IClienteEndereco;
            endereco.codigo = 0;
            endereco.principal = true;
            endereco.identificacao = 'PRINCIPAL';
            endereco.cep = retornoConsultaCnpj.endereco.cep;
            endereco.numero = retornoConsultaCnpj.endereco.numero;
            endereco.endereco = retornoConsultaCnpj.endereco.logadouro;
            endereco.complemento = retornoConsultaCnpj.endereco.complemento;
            endereco.bairro = retornoConsultaCnpj.endereco.bairro;
            endereco.codigoCidade = retornoConsultaCnpj.endereco.codigoCidade;
            state.reservaCriacao.cliente.enderecos.push(endereco);
          }
        }
      }
    }

    function definirBoxSelecionado(box:IAreaBox) {
      state.reservaCriacao.reserva.codigoBox = box.codigo;
      state.boxSelecionado = box;
      state.passo = 2;
    }

    function preencheMedidasMapa() {
      const myImg = document.querySelector('#AreaImgMapa') as any;
      if (myImg !== null) {
        if (state.area.mapaAltura === 0) {
          state.area.mapaAltura = myImg.naturalHeight;
        }

        if (state.area.mapaLargura === 0) {
          state.area.mapaLargura = myImg.naturalWidth;
        }
      }
    }

    function adicionarEndereco() {
      const endereco:IClienteEndereco = {} as IClienteEndereco;
      endereco.codigo = 0;
      endereco.principal = true;
      endereco.identificacao = 'Principal';
      endereco.cep = '';
      endereco.numero = '';
      endereco.endereco = '';
      endereco.complemento = '';
      endereco.bairro = '';
      state.reservaCriacao.cliente.enderecos.push(endereco);
    }

    function limparDados() {
      state.area = {} as IArea;
      state.area.codigo = 0;
      state.area.status = true;
      state.area.tipoControle = 0;
      state.area.valoresPorPeriodo = [];
      state.area.boxesStatus = [];
      state.area.boxes = [];
      state.area.mapa = '';

      state.reservaCriacao.cliente = {} as ICliente;
      state.reservaCriacao.cliente.codigo = 0;
      state.reservaCriacao.cliente.status = true;
      state.reservaCriacao.cliente.tipoPessoa = 1;
      state.reservaCriacao.cliente.genero = 1;
      state.reservaCriacao.cliente.enderecos = [];
      adicionarEndereco();
      state.reservaCriacao.cliente.enderecos[0].principal = true;
    }

    function obterCorStatus(box:IAreaBox):string {
      if (UtilitarioGeral.validaLista(state.boxesReservados)) {
        if (state.boxesReservados.some((c) => c.codigoBox === box.codigo)) {
          const statusReservado = state.area.boxesStatus.find((c) => c.status === EBoxStatus.Reservado);
          if (statusReservado !== undefined) {
            return statusReservado.cor;
          }
        }
      }

      const statusIndisponivel = state.area.boxesStatus.find((c) => c.status === EBoxStatus.Indisponivel);
      if (statusIndisponivel !== undefined) {
        return statusIndisponivel.cor;
      }

      return '';
    }

    function boxDisponivel(box:IAreaBox):boolean {
      if (UtilitarioGeral.validaLista(state.boxesReservados)) {
        if (state.boxesReservados.some((c) => c.codigoBox === box.codigo)) { return false; }
      }

      return box.codigoBoxStatus === state.statusBoxDisponivel.codigo;
    }

    function verificaIdentificaoCliente() {
      state.mensagemAlerta = '';
      if (state.cpfCnpjIdentificacao !== state.reservaCriacao.cliente.cpfCnpj) {
        state.mensagemAlerta = 'CPF/CNPJ inválido ou não utilizado no cadastro dessa conta, tente outro.';
        return;
      }

      state.passoIndentificacao = 3;
      state.apresentarDadosCliente = true;
    }

    function verificaCamposCliente():boolean {
      if (!UtilitarioGeral.valorValido(state.reservaCriacao.cliente.cpfCnpj)) {
        return false;
      }

      if (!UtilitarioGeral.valorValido(state.reservaCriacao.cliente.nomeRazaoSocial)) {
        return false;
      }

      if (!UtilitarioGeral.valorValido(state.reservaCriacao.cliente.email)) {
        return false;
      }

      if (!UtilitarioGeral.valorValido(state.reservaCriacao.cliente.enderecos[0].cep)) {
        return false;
      }

      if (!UtilitarioGeral.valorValido(state.reservaCriacao.cliente.enderecos[0].endereco)) {
        return false;
      }

      if (!UtilitarioGeral.valorValido(state.reservaCriacao.cliente.enderecos[0].bairro)) {
        return false;
      }

      state.mensagemAlerta = '';
      return true;
    }

    async function irParaConfirmacao() {
      if (state.formaPagamento.tipo === ETipoFormaPagamento.CartaoCredito && state.formaPagamento.tipoIntegracao === ETipoIntegracaoPagamento.ASAAS) {
        if (!UtilitarioGeral.valorValido(state.reservaCriacao.dadosCartao.numero)) {
          state.mensagemAlerta = 'É necessário informar o número do cartão!';
          return;
        }

        if (!UtilitarioGeral.valorValido(state.reservaCriacao.dadosCartao.nome)) {
          state.mensagemAlerta = 'É necessário informar o nome impresso no cartão!';
          return;
        }

        if (!UtilitarioGeral.valorValido(state.reservaCriacao.dadosCartao.numero)) {
          state.mensagemAlerta = 'É necessário informar o número do cartão!';
          return;
        }

        if (!UtilitarioGeral.valorValido(state.reservaCriacao.dadosCartao.mesExpiracao)) {
          state.mensagemAlerta = 'É necessário informar o mês de validade do cartão!';
          return;
        }

        if (!UtilitarioGeral.valorValido(state.reservaCriacao.dadosCartao.anoExpiracao)) {
          state.mensagemAlerta = 'É necessário informar o ano de validade do cartão!';
          return;
        }

        if (!UtilitarioGeral.valorValido(state.reservaCriacao.dadosCartao.csv)) {
          state.mensagemAlerta = 'É necessário informar o código de segurança do cartão!';
          return;
        }

        if (!UtilitarioGeral.valorValido(state.reservaCriacao.dadosTitularCartao.cpfCnpj)) {
          state.mensagemAlerta = 'É necessário informar o CPF/CNPJ do titular do cartão!';
          return;
        }

        if (!(state.reservaCriacao.dadosTitularCartao.cpfCnpj.length === 11 || state.reservaCriacao.dadosTitularCartao.cpfCnpj.length === 14)) {
          state.mensagemAlerta = 'Confirme o CPF/CNPJ digitado do titular do cartão!';
          return;
        }

        if (!UtilitarioGeral.valorValido(state.reservaCriacao.dadosTitularCartao.nomeCompleto)) {
          state.mensagemAlerta = 'É necessário informar o Nome completo do titular do cartão!';
          return;
        }

        if (!UtilitarioGeral.valorValido(state.reservaCriacao.quantidadeParcelas)) {
          state.mensagemAlerta = 'Selecione a quantidade de Parcelas!';
          return;
        }

        if (state.reservaCriacao.quantidadeParcelas === 0) {
          state.mensagemAlerta = 'Selecione a quantidade de Parcelas!';
          return;
        }
      }

      if (state.reservaCriacao.cliente.codigo === 0) {
        state.reservaCriacao.cliente.senha = state.reservaCriacao.cliente.cpfCnpj;
        state.reservaCriacao.cliente.status = true;
        state.reservaCriacao.cliente.emailConfirmado = true;

        const resposta = await servicoReserva.cadastrarCliente(state.reservaCriacao.cliente);
        if (resposta.status === EStatusResposta.Sucesso) {
          state.reservaCriacao.cliente.codigo = resposta.codigo;
          state.reservaCriacao.reserva.codigoCliente = resposta.codigo;
        } else {
          state.mensagemAlerta = resposta.mensagens[0].subMensagens[0];
          return;
        }
      }

      state.reservaCriacao.dadosTitularCartao.email = state.reservaCriacao.cliente.email;
      state.reservaCriacao.dadosTitularCartao.cep = state.reservaCriacao.cliente.enderecos[0].cep;
      state.reservaCriacao.dadosTitularCartao.numeroEndereco = state.reservaCriacao.cliente.enderecos[0].numero;
      state.reservaCriacao.dadosTitularCartao.enderecoComplemento = state.reservaCriacao.cliente.enderecos[0].complemento;
      state.reservaCriacao.dadosTitularCartao.telefone = state.reservaCriacao.cliente.telefone;
      const respostaReserva = await servicoReserva.criarReserva(state.reservaCriacao);
      if (respostaReserva.status === EStatusResposta.Sucesso) {
        state.reservaCriacao.reserva.numeroIdentificacao = respostaReserva.identificacao;
        state.passo = 5;
      } else {
        state.mensagemAlerta = respostaReserva.mensagem;
      }
    }

    onBeforeMount(async () => {
      limparDados();

      if (route.params !== undefined && UtilitarioGeral.validaCodigo(route.params.parceiro)) {
        gerenciadorAutenticacao.salvarIdentificadorParceiro(route.params.parceiro.toString());
      }

      storeApp.mutations.atualizarParceiroAutenticado(storeApp.getters.obterParceiroAutenticado(route.params.parceiro.toString()));
      document.documentElement.style.setProperty('--primary', storeApp.state.parceiroAutenticado.corPrimaria);
      document.documentElement.style.setProperty('--primary-light', storeApp.state.parceiroAutenticado.corSecundaria);
      document.documentElement.style.setProperty('--primary-dark', storeApp.state.parceiroAutenticado.corEscura);
    });

    onMounted(() => {
      window.addEventListener('resize', updateParkingSpacePositions);
    });

    watch(height, (value) => {
      enviarAtualizacaoAltura(value);
    },
    { deep: true, immediate: true });

    function montarLinkWhatsApp(): string {
      const link = `https://api.whatsapp.com/send?1=pt_BR&phone=5547992021952&text=Olá, eu gostaria reservar o Box: *${state.boxSelecionado.identificacao}* da área: *${state.area.nome}*. %0ACheck-In: *${UtilitarioData.aplicaFormatoData(state.reservaCriacao.reserva.dataCheckIn)}* %0ACheck-Out: *${UtilitarioData.aplicaFormatoData(state.reservaCriacao.reserva.dataCheckOut)}* %0AQuantidade de Pessoas: *${(state.reservaCriacao.reserva.qtdePessoasInteira + state.reservaCriacao.reserva.qtdePessoasMeia + state.reservaCriacao.reserva.qtdePessoasIsenta)}* %0A`;
      return link;
    }
    return {
      appBase,
      state,
      corDisponivel,
      el,
      height,
      ETipoControleArea,
      UtilitarioGeral,
      UtilitarioMascara,
      preencheMedidasMapa,
      obterCorStatus,
      boxDisponivel,
      obterAreaComBoxReservados,
      consultarDisponibilidade,
      definirAreaSelecionada,
      definirBoxSelecionado,
      consultaCep,
      consultaCnpj,
      obterClienteEmail,
      obterClienteCpfCnpj,
      visible,
      setVisible,
      definePrimeiroPasso,
      consultarDisponibilidadeBoxSelecionado,
      calcularValoresReserva,
      verificaIdentificaoCliente,
      verificaCamposCliente,
      irParaConfirmacao,
      montarLinkWhatsApp,
    };
  },
});
