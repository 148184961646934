
import { computed, defineComponent } from 'vue';
import { IFormaPagamento } from '@/models/Entidades/IFormaPagamento';
import { ETipoFormaPagamento } from '@/models/Enumeradores/ETipoFormaPagamento';
import CampoCpfCnpj from '@/core/components/UI/CampoCpfCnpj.vue';
import UtilitarioMascara from '@/core/utilitarios/UtilitarioMascara';
import { IDTOReservaCriacao } from '@/models/DTO/IDTOReservaCriacao';

export interface IDTOParcelaCartao {
    parcelas: number;
    apresentacao: string;
    valor:number;
}

export default defineComponent({
  name: 'RadioFormaPagamento',
  components: {
    CampoCpfCnpj,
  },
  props: {
    codigoSelecionado: {
      type: Number,
      default: 0,
    },
    valorCompra: {
      type: Number,
      default: 0,
    },
    dadosReserva: {
      type: Object as () => IDTOReservaCriacao,
      default: {} as IDTOReservaCriacao,
    },
    formasPagamentos: {
      type: Array as () => IFormaPagamento[],
      required: true,
    },
  },
  emits: ['update:codigoSelecionado', 'update:dadosReserva', 'change'],
  setup(props, { emit }) {
    function valorParcelado(valor: number, parcelas: number): string {
      if (valor > 0) {
        return UtilitarioMascara.mascararValor((valor / parcelas), 2);
      }
      return UtilitarioMascara.mascararValor(0, 2);
    }

    function montaApresentacaoParcelas(formaPagamento:IFormaPagamento):IDTOParcelaCartao[] {
      const parcelas:IDTOParcelaCartao[] = [];
      for (let index = 1; index < formaPagamento.cartaoQuantidadeParcelas + 1; index += 1) {
        const parcela:IDTOParcelaCartao = {} as IDTOParcelaCartao;
        parcela.parcelas = index;
        parcela.apresentacao = `${index}x de R$ ${valorParcelado(props.valorCompra, index)}`;
        parcelas.push(parcela);
      }

      return parcelas;
    }
    const computedcodigoSelecionado = computed({
      get: () => props.codigoSelecionado,
      set: (codigo: number) => {
        emit('update:codigoSelecionado', codigo);
        emit('change');
      },
    });

    const computedDadosPagamentoPedido = computed({
      get: () => props.dadosReserva,
      set: (dadosReserva: IDTOReservaCriacao) => {
        emit('update:dadosReserva', dadosReserva);
      },
    });

    return {
      props,
      computedcodigoSelecionado,
      computedDadosPagamentoPedido,
      ETipoFormaPagamento,
      montaApresentacaoParcelas,
    };
  },
});
