import ApiCommerce from '@/core/apis/ApiCommerce';
import { IReserva } from '@/models/Entidades/IReserva';
import { IResposta } from '@/core/models/IResposta';
import { IServicoBase } from '@/core/models/IServicoBase';
import { IRepostaConsulta } from '@/core/models/IRepostaConsulta';
import { IReservaParametrosConsulta } from '@/models/ParametrosRequisicao/Consulta/IReservaParametrosConsulta';
import { IDTOReserva } from '@/models/DTO/IDTOReserva';
import { IParametrosBuscaRapida } from '@/core/models/Consulta/IParametrosBuscaRapida';
import { IItemBuscaRapida } from '@/core/models/Consulta/IItemBuscaRapida';
import { IRetornoArquivo } from '@/core/models/IRetornoArquivo';
import { IRetornoRelatorio } from '@/core/models/IRetornoRelatorio';
import { IDTOAreaDisponibilidade } from '@/models/DTO/IDTOAreaDisponibilidade';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import { IDTOAreaReserva } from '@/models/DTO/IDTOAreaReserva';
import { ICliente } from '@/models/Entidades/ICliente';
import { IFormaPagamento } from '@/models/Entidades/IFormaPagamento';
import { IEquipamento } from '@/models/Entidades/IEquipamento';
import { IDTOReservaCriacao } from '@/models/DTO/IDTOReservaCriacao';
import { IRespostaReserva } from '@/core/models/IRepostaReserva';
import { IArea } from '@/models/Entidades/IArea';
import { IDTOReservaDisponibilidadeBox } from '@/models/DTO/IDTOReservaDisponibilidadeBox';
/**
 * Serviço de Reservas
 */

class ServicoReserva implements IServicoBase<IReserva> {
  endPoint = 'reservas';

  apiCommerce = new ApiCommerce();

  public async incluir(reserva: IReserva): Promise<IResposta> {
    const result: any = await this.apiCommerce.post(`${this.endPoint}`, reserva);
    return result.data;
  }

  public async criarReserva(dados: IDTOReservaCriacao): Promise<IRespostaReserva> {
    const result: any = await this.apiCommerce.post(`${this.endPoint}/nova`, dados);
    return result.data;
  }

  public async cadastrarCliente(cliente: ICliente): Promise<IResposta> {
    const result: any = await this.apiCommerce.post(`${this.endPoint}/cliente`, cliente);
    return result.data;
  }

  public async atualizar(reserva: IReserva): Promise<IResposta> {
    const result: any = await this.apiCommerce.put(`${this.endPoint}/${reserva.codigo}`, reserva);
    return result.data;
  }

  public async obter(codigo: number): Promise<IReserva> {
    const result: any = await this.apiCommerce.get(`${this.endPoint}/${codigo}`);
    return result.data;
  }

  public async obterAreaResumida(codigo: number): Promise<IArea> {
    const result: any = await this.apiCommerce.get(`${this.endPoint}/areas/${codigo}/resumida`);
    return result.data;
  }

  public async obterEquipamentos(): Promise<IEquipamento[]> {
    const result: any = await this.apiCommerce.get(`${this.endPoint}/equipamentos`);
    return result.data;
  }

  public async consultarDisponibilidade(dataCheckIn: string, dataCheckOut: string): Promise<IDTOAreaDisponibilidade[]> {
    let parametrosAdicionais = '';

    if (UtilitarioGeral.valorValido(dataCheckIn)) {
      parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
      parametrosAdicionais += `dataCheckIn=${dataCheckIn}`;
    }

    if (UtilitarioGeral.valorValido(dataCheckOut)) {
      parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
      parametrosAdicionais += `dataCheckOut=${dataCheckOut}`;
    }
    const result: any = await this.apiCommerce.get(`${this.endPoint}/consultar-disponibilidade${parametrosAdicionais}`);
    return result.data;
  }

  public async obterAreaComBoxReservados(codigoArea: number, dataCheckIn: string, dataCheckOut: string, periodo?:number): Promise<IDTOAreaReserva> {
    let parametrosAdicionais = '';

    if (UtilitarioGeral.valorValido(dataCheckIn)) {
      parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
      parametrosAdicionais += `dataCheckIn=${dataCheckIn}`;
    }

    if (UtilitarioGeral.valorValido(dataCheckOut)) {
      parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
      parametrosAdicionais += `dataCheckOut=${dataCheckOut}`;
    }

    if (periodo !== undefined) {
      parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
      parametrosAdicionais += `periodo=${periodo}`;
    }
    const result: any = await this.apiCommerce.get(`${this.endPoint}/areas/${codigoArea}${parametrosAdicionais}`);
    return result.data;
  }

  public async obterReservasDisponibilidadeBox(codigoArea: number, dataCheckIn: string, dataCheckOut: string, periodo?:number): Promise<IDTOReservaDisponibilidadeBox[]> {
    let parametrosAdicionais = '';

    if (UtilitarioGeral.valorValido(dataCheckIn)) {
      parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
      parametrosAdicionais += `dataCheckIn=${dataCheckIn}`;
    }

    if (UtilitarioGeral.valorValido(dataCheckOut)) {
      parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
      parametrosAdicionais += `dataCheckOut=${dataCheckOut}`;
    }

    if (periodo !== undefined) {
      parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
      parametrosAdicionais += `periodo=${periodo}`;
    }
    const result: any = await this.apiCommerce.get(`${this.endPoint}/areas/${codigoArea}/disponibilidade/box${parametrosAdicionais}`);
    return result.data;
  }

  public async obterFormaPagamento(codigo: number): Promise<IFormaPagamento> {
    const result: any = await this.apiCommerce.get(`${this.endPoint}/formas-pagamentos/${codigo}`);
    return result.data;
  }

  public async obterClientePorEmail(email: string): Promise<ICliente> {
    const result: any = await this.apiCommerce.get(`${this.endPoint}/clientes/email/${email}`);
    return result.data;
  }

  public async obterClientePorCpfCnpj(cpfCnpj: string): Promise<ICliente> {
    const result: any = await this.apiCommerce.get(`${this.endPoint}/clientes/cpfCnpj/${cpfCnpj}`);
    return result.data;
  }

  public async obterReservas(parametrosConsulta: IReservaParametrosConsulta): Promise<IRepostaConsulta<IDTOReserva>> {
    let parametrosAdicionais = '';

    if (parametrosConsulta.valorPesquisa !== undefined) {
      parametrosAdicionais += `?ValorPesquisa=${parametrosConsulta.valorPesquisa}`;
    }

    if (parametrosConsulta.dataInicial !== undefined) {
      parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
      parametrosAdicionais += `dataInicial=${parametrosConsulta.dataInicial}`;
    }

    if (parametrosConsulta.dataFinal !== undefined) {
      parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
      parametrosAdicionais += `dataFinal=${parametrosConsulta.dataFinal}`;
    }

    if (parametrosConsulta.codigoArea !== undefined) {
      parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
      parametrosAdicionais += `CodigoArea=${parametrosConsulta.codigoArea}`;
    }

    if (parametrosConsulta.codigoBox !== undefined) {
      parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
      parametrosAdicionais += `CodigoBox=${parametrosConsulta.codigoBox}`;
    }

    if (parametrosConsulta.status !== undefined) {
      parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
      parametrosAdicionais += `Status=${parametrosConsulta.status}`;
    }

    if (parametrosConsulta.periodo !== undefined) {
      parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
      parametrosAdicionais += `Periodo=${parametrosConsulta.periodo}`;
    }

    if (parametrosConsulta.numeroPagina !== undefined && parametrosConsulta.numeroPagina > 0) {
      parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
      parametrosAdicionais += `NumeroPagina=${parametrosConsulta.numeroPagina}`;
    }
    if (parametrosConsulta.qtdeRegistrosPagina !== undefined && parametrosConsulta.qtdeRegistrosPagina > 0) {
      parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
      parametrosAdicionais += `QtdeRegistrosPagina=${parametrosConsulta.qtdeRegistrosPagina}`;
    }
    if (parametrosConsulta.qtdeRegistrosTotal !== undefined && parametrosConsulta.qtdeRegistrosTotal > 0) {
      parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
      parametrosAdicionais += `QtdeRegistrosTotal=${parametrosConsulta.qtdeRegistrosTotal}`;
    }
    if (parametrosConsulta.ordenacao !== undefined) {
      parametrosConsulta.ordenacao.forEach((item) => {
        parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
        parametrosAdicionais += `Ordenacao=${item}`;
      });
    }
    if (parametrosConsulta.codigos !== undefined) {
      parametrosConsulta.codigos.forEach((codigo) => {
        parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
        parametrosAdicionais += `Codigos=${codigo}`;
      });
    }
    const result: any = await this.apiCommerce.get(`${this.endPoint}${parametrosAdicionais}`);
    return result.data;
  }

  public async buscaRapida(parametrosConsultaRapida: IParametrosBuscaRapida): Promise<IItemBuscaRapida[]> {
    const parametros = this.apiCommerce.obterParametrosBuscaRapida(parametrosConsultaRapida);
    const result: any = await this.apiCommerce.get(`${this.endPoint}/busca-rapida${parametros}`);
    return result.data;
  }

  public async deletar(codigo: number): Promise<IResposta> {
    const result: any = await this.apiCommerce.delete(`${this.endPoint}/${codigo}`);
    return result.data;
  }

  public async gerarRelatorioResumoDiario(parametrosConsulta: IReservaParametrosConsulta): Promise<IRetornoRelatorio> {
    let parametrosAdicionais = '';

    if (parametrosConsulta.valorPesquisa !== undefined) {
      parametrosAdicionais += `?ValorPesquisa=${parametrosConsulta.valorPesquisa}`;
    }

    if (parametrosConsulta.dataInicial !== undefined) {
      parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
      parametrosAdicionais += `dataInicial=${parametrosConsulta.dataInicial}`;
    }

    if (parametrosConsulta.dataFinal !== undefined) {
      parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
      parametrosAdicionais += `dataFinal=${parametrosConsulta.dataFinal}`;
    }

    if (parametrosConsulta.codigoArea !== undefined) {
      parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
      parametrosAdicionais += `CodigoArea=${parametrosConsulta.codigoArea}`;
    }

    if (parametrosConsulta.codigoBox !== undefined) {
      parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
      parametrosAdicionais += `CodigoBox=${parametrosConsulta.codigoBox}`;
    }

    if (parametrosConsulta.status !== undefined) {
      parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
      parametrosAdicionais += `Status=${parametrosConsulta.status}`;
    }

    if (parametrosConsulta.periodo !== undefined) {
      parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
      parametrosAdicionais += `Periodo=${parametrosConsulta.periodo}`;
    }

    if (parametrosConsulta.numeroPagina !== undefined && parametrosConsulta.numeroPagina > 0) {
      parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
      parametrosAdicionais += `NumeroPagina=${parametrosConsulta.numeroPagina}`;
    }
    if (parametrosConsulta.qtdeRegistrosPagina !== undefined && parametrosConsulta.qtdeRegistrosPagina > 0) {
      parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
      parametrosAdicionais += `QtdeRegistrosPagina=${parametrosConsulta.qtdeRegistrosPagina}`;
    }
    if (parametrosConsulta.qtdeRegistrosTotal !== undefined && parametrosConsulta.qtdeRegistrosTotal > 0) {
      parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
      parametrosAdicionais += `QtdeRegistrosTotal=${parametrosConsulta.qtdeRegistrosTotal}`;
    }
    if (parametrosConsulta.ordenacao !== undefined) {
      parametrosConsulta.ordenacao.forEach((item) => {
        parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
        parametrosAdicionais += `Ordenacao=${item}`;
      });
    }
    if (parametrosConsulta.codigos !== undefined) {
      parametrosConsulta.codigos.forEach((codigo) => {
        parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
        parametrosAdicionais += `Codigos=${codigo}`;
      });
    }
    const result: any = await this.apiCommerce.get(`${this.endPoint}/relatorio/resumo-diario${parametrosAdicionais}`);
    return result.data;
  }

  public async gerarRelatorioListagem(parametrosConsulta: IReservaParametrosConsulta): Promise<IRetornoRelatorio> {
    let parametrosAdicionais = '';

    if (parametrosConsulta.valorPesquisa !== undefined) {
      parametrosAdicionais += `?ValorPesquisa=${parametrosConsulta.valorPesquisa}`;
    }

    if (parametrosConsulta.dataInicial !== undefined) {
      parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
      parametrosAdicionais += `dataInicial=${parametrosConsulta.dataInicial}`;
    }

    if (parametrosConsulta.dataFinal !== undefined) {
      parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
      parametrosAdicionais += `dataFinal=${parametrosConsulta.dataFinal}`;
    }

    if (parametrosConsulta.codigoArea !== undefined) {
      parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
      parametrosAdicionais += `CodigoArea=${parametrosConsulta.codigoArea}`;
    }

    if (parametrosConsulta.codigoBox !== undefined) {
      parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
      parametrosAdicionais += `CodigoBox=${parametrosConsulta.codigoBox}`;
    }

    if (parametrosConsulta.status !== undefined) {
      parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
      parametrosAdicionais += `Status=${parametrosConsulta.status}`;
    }

    if (parametrosConsulta.periodo !== undefined) {
      parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
      parametrosAdicionais += `Periodo=${parametrosConsulta.periodo}`;
    }

    if (parametrosConsulta.numeroPagina !== undefined && parametrosConsulta.numeroPagina > 0) {
      parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
      parametrosAdicionais += `NumeroPagina=${parametrosConsulta.numeroPagina}`;
    }
    if (parametrosConsulta.qtdeRegistrosPagina !== undefined && parametrosConsulta.qtdeRegistrosPagina > 0) {
      parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
      parametrosAdicionais += `QtdeRegistrosPagina=${parametrosConsulta.qtdeRegistrosPagina}`;
    }
    if (parametrosConsulta.qtdeRegistrosTotal !== undefined && parametrosConsulta.qtdeRegistrosTotal > 0) {
      parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
      parametrosAdicionais += `QtdeRegistrosTotal=${parametrosConsulta.qtdeRegistrosTotal}`;
    }
    if (parametrosConsulta.ordenacao !== undefined) {
      parametrosConsulta.ordenacao.forEach((item) => {
        parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
        parametrosAdicionais += `Ordenacao=${item}`;
      });
    }
    if (parametrosConsulta.codigos !== undefined) {
      parametrosConsulta.codigos.forEach((codigo) => {
        parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
        parametrosAdicionais += `Codigos=${codigo}`;
      });
    }
    const result: any = await this.apiCommerce.get(`${this.endPoint}/relatorio/listagem${parametrosAdicionais}`);
    return result.data;
  }
}

export default ServicoReserva;
