
import { computed, defineComponent } from 'vue';

export default defineComponent({
  name: 'CampoAdicionarDiminuirNumero',
  components: {
  },
  props: {
    valor: {
      type: Number,
      default: 0,
    },
    valorMinimo: {
      type: Number,
      default: 1,
    },
    titulo: {
      type: String,
      default: '',
    },
    informacao: {
      type: String,
      default: '',
    },
    editavel: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:valor', 'change'],
  setup(props, { emit }) {
    const computedValor = computed({
      get: () => props.valor,
      set: (valor: number) => {
        if (valor === undefined || valor === null) {
          emit('update:valor', props.valorMinimo);
        } else {
          emit('update:valor', valor);
        }
      },
    });

    function diminuir() {
      const numero = computedValor.value - 1;
      if (numero < props.valorMinimo) {
        computedValor.value = props.valorMinimo;
      } else {
        computedValor.value = numero;
      }

      emit('change');
    }

    function adicionar() {
      computedValor.value += 1;
      emit('change');
    }
    return {
      props,
      computedValor,
      diminuir,
      adicionar,
    };
  },
});
