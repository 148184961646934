import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0b3e71fe"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "ui-adicionar-diminuir-numero-grupo" }
const _hoisted_2 = { class: "ui-adicionar-diminuir-numero-titulo ui-ta-left" }
const _hoisted_3 = {
  key: 0,
  class: "ui-adicionar-diminuir-numero-informacao"
}
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { class: "ui-adicionar-diminuir-numero-titulo ui-adicionar-diminuir-numero-apresentacao" }
const _hoisted_6 = { key: 3 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createTextVNode(_toDisplayString(_ctx.props.titulo), 1),
      (_ctx.props.informacao)
        ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.props.informacao), 1))
        : _createCommentVNode("", true)
    ]),
    (_ctx.props.editavel)
      ? (_openBlock(), _createElementBlock("span", {
          key: 0,
          class: "ui-adicionar-diminuir-numero",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.diminuir()))
        }, "-"))
      : (_openBlock(), _createElementBlock("span", _hoisted_4)),
    _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.computedValor), 1),
    (_ctx.props.editavel)
      ? (_openBlock(), _createElementBlock("span", {
          key: 2,
          class: "ui-adicionar-diminuir-numero",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.adicionar()))
        }, "+"))
      : (_openBlock(), _createElementBlock("span", _hoisted_6))
  ]))
}